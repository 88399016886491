import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Hamburger from '@shared/Hamburger/Hamburger';
import { Link, navigate } from 'gatsby';
import { CloseX } from '@shared/Icons/Icons';
import useWindowSize from '@hooks/useWindowSize';
import { MOBILE_SIZE, css, q, trackEvent } from '@utils';

import Styles from './Nav.module.scss';

const Nav = ({ socialLinks, pageName }) => {
  const [open, setOpen] = useState(false);
  const [headerEl, setHeaderEl] = useState(null);
  const [mounted, setMounted] = useState(false);
  const { windowWidth } = useWindowSize();

  const onLinkClick = (link, label, e) => {
    e.preventDefault();

    trackEvent({
      category: 'Menu',
      action: `${window.location.origin}${link}`,
      label,
    });

    if (link === window.location.pathname) {
      setOpen(false);
    } else {
      navigate(link);
    }
  };

  const onSocialLinkClick = (e, label, url) => {
    e.preventDefault();
    const newWindow = window.open(url, '_blank');
    newWindow.opener = null;

    trackEvent({
      category: 'Social Module',
      action: url,
      label,
    });
  };

  useEffect(() => {
    setMounted(true);
    setHeaderEl(q('#header'));
  });

  if (!mounted) {
    return null;
  }

  return (
    <div className={css(Styles.nav, open && Styles.open)}>
      <div
        role="presentation"
        className={Styles.tintOverlay}
        onClick={() => {
          setOpen(false);
        }}
      />
      {ReactDOM.createPortal(
        <Hamburger
          pageName={pageName}
          open={open}
          onClick={() => {
            setOpen(!open);
          }}
        />,
        headerEl
      )}
      <div className={Styles.navWrapper}>
        <button
          type="button"
          className={Styles.closeButton}
          onClick={() => {
            setOpen(false);
          }}
        >
          {windowWidth > MOBILE_SIZE && (
            <div className={Styles.closeText}>Close</div>
          )}
          <CloseX iconStyles={Styles.closeIcon} />
        </button>
        <div className={Styles.navContentContainer}>
          <ul className={Styles.navLinks}>
            <li className={Styles.navLink}>
              <Link
                onClick={e => {
                  onLinkClick('/', 'The Foundation', e);
                }}
                to="/"
                activeClassName={Styles.activeLink}
              >
                The Foundation
              </Link>
            </li>
            <li className={Styles.navLink}>
              <Link
                onClick={e => {
                  onLinkClick('/impact', 'Our Impact', e);
                }}
                to="/impact"
                activeClassName={Styles.activeLink}
              >
                Our Impact
              </Link>
            </li>
            <li className={Styles.navLink}>
              <Link
                onClick={e => {
                  onLinkClick('/grant', 'Apply For A Grant', e);
                }}
                to="/grant"
                activeClassName={Styles.activeLink}
              >
                Apply For A Grant
              </Link>
            </li>
            <li className={Styles.navLink}>
              <Link
                onClick={e => {
                  onLinkClick('/donate', 'Donate', e);
                }}
                to="/donate"
                activeClassName={Styles.activeLink}
              >
                Donate
              </Link>
            </li>
            <li className={Styles.navLink}>
              <Link
                onClick={e => {
                  onLinkClick('/training', 'Training', e);
                }}
                to="/training"
                activeClassName={Styles.activeLink}
              >
                Training Hub
              </Link>
            </li>
          </ul>

          <div className={Styles.navFooter}>
            <ul className={Styles.navFooterLinks}>
              {socialLinks.map(({ label, url }, index) => {
                return (
                  <div className={Styles.navFooterLink} key={index}>
                    <a
                      href={url}
                      onClick={e => {
                        onSocialLinkClick(e, label, url);
                      }}
                    >
                      {label}
                    </a>
                  </div>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
