import React, { useState, useRef, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import useWindowSize from '@hooks/useWindowSize';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { q, trackEvent } from '@utils';
import Styles from './DonateButton.module.scss';

const DonateButton = ({ pageName }) => {
  const [donationModuleEl, setDonationModuleEl] = useState(null);
  const donateButtonRef = useRef(null);
  const [positionFixed, setPositionFixed] = useState(false);
  const { windowWidth } = useWindowSize();

  const onLinkClick = e => {
    e.preventDefault();

    trackEvent({
      category: pageName || '',
      action: `${window.location.origin}/donate`,
      label: 'Donate',
    });

    navigate('/donate');
  };

  const updateScrollPosition = () => {
    if (donationModuleEl) {
      const donationModuleBoundingBox = donationModuleEl.getBoundingClientRect();
      const offset = windowWidth >= 768 ? (windowWidth >= 1248 ? 60 : 50) : 30;
      const boundingOffset =
        windowWidth >= 768 ? (windowWidth >= 1248 ? 60 : 50) : 40;
      if (
        donationModuleBoundingBox.top <
        window.innerHeight - (boundingOffset + offset)
      ) {
        if (positionFixed) {
          if (windowWidth >= 768) {
            donateButtonRef.current.style.animation = `none`;
            donateButtonRef.current.style.transform = `scale(0)`;
          } else {
            donateButtonRef.current.style.position = 'absolute';
            donateButtonRef.current.style.top = `${window.pageYOffset +
              donationModuleBoundingBox.top -
              offset}px`;
          }

          setPositionFixed(false);
        }
      } else if (!positionFixed) {
        if (windowWidth >= 768) {
          donateButtonRef.current.style.animation = `none`;
          donateButtonRef.current.style.transform = `scale(1)`;
        } else {
          donateButtonRef.current.style.position = 'fixed';
          donateButtonRef.current.style.top = 'initial';
        }

        setPositionFixed(true);
      }
    }
  };

  useScrollPosition(() => {
    updateScrollPosition();
  });

  const onResize = () => {
    updateScrollPosition();
  };

  const bindListeners = () => {
    window.addEventListener('resize', onResize);
  };

  useEffect(() => {
    setDonationModuleEl(q('#DonationModule'));
    bindListeners();

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <div className={Styles.donateButtonContainer} ref={donateButtonRef}>
      <Link to="/donate" onClick={onLinkClick}>
        <div className={Styles.donateButton}>
          <div className={Styles.buttonText}>Donate</div>
        </div>
      </Link>
    </div>
  );
};

export default DonateButton;
