import React from 'react';

export const ScrollArrow = ({ iconStyles }) => {
  return (
    <svg
      className={iconStyles}
      width="18.243"
      height="27.28"
      viewBox="0 0 18.243 27.28"
    >
      <g transform="translate(16.121 25.78) rotate(180)">
        <path
          d="M0,0,7,7l7-7"
          transform="translate(14 7) rotate(180)"
          fill="none"
          stroke="#ffa200"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <line
          y2="24"
          transform="translate(7 0.28)"
          fill="none"
          stroke="#ffa200"
          strokeLinecap="round"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
};

export const GrantArrow = ({ iconStyles }) => {
  return (
    <svg
      className={iconStyles}
      width="29.96"
      height="21.178"
      viewBox="0 0 29.96 21.178"
    >
      <g transform="translate(-902.145 -3095.444)">
        <g transform="translate(-59 -2463)">
          <g transform="translate(988.983 5560.565) rotate(90)">
            <path
              d="M0,0,8.468,8.468,16.935,0"
              transform="translate(16.935 8.468) rotate(180)"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeWidth="3"
            />
            <line
              y2="26"
              transform="translate(8.468 0.339)"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeWidth="3"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const CheckMark = ({ iconStyles }) => {
  return (
    <svg
      className={iconStyles}
      width="125.053"
      height="96.647"
      viewBox="0 0 125.053 96.647"
    >
      <path
        d="M-17993.807,166.271l45.715,45.62,68.314-80.742"
        transform="translate(17999.104 -126.305)"
        fill="none"
        stroke="#0b1e28"
        strokeWidth="20"
      />
    </svg>
  );
};

export const Arrow = ({ iconStyles }) => {
  return (
    <svg
      className={iconStyles}
      width="22.169"
      height="11.266"
      viewBox="0 0 22.169 11.266"
    >
      <g transform="translate(21.169 1.414) rotate(90)">
        <path
          d="M0,0,4.219,4.219,8.438,0"
          transform="translate(8.438 4.219) rotate(180)"
          fill="none"
          stroke="#ffa200"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          y2="20"
          transform="translate(4.219 0.169)"
          fill="none"
          stroke="#ffa200"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export const ShortFatArrow = ({ iconStyles }) => {
  return (
    <svg
      className={iconStyles}
      width="7.621"
      height="12.243"
      viewBox="0 0 7.621 12.243"
    >
      <path
        d="M0,4,2.25,1.75,4,0,5.667,1.667,8,4"
        transform="translate(1.5 10.121) rotate(-90)"
        fill="none"
        stroke="#ffa200"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </svg>
  );
};

export const PlusIcon = ({ iconStyles }) => {
  return (
    <svg className={iconStyles} width="16" height="16" viewBox="0 0 16 16">
      <g data-name="Group 744" transform="translate(1 1)">
        <line
          y2="14"
          transform="translate(7)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <line
          y2="14"
          transform="translate(14 7) rotate(90)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export const LargeArrow = ({ iconStyles }) => {
  return (
    <svg
      className={iconStyles}
      width="32.705"
      height="61.411"
      viewBox="0 0 32.705 61.411"
    >
      <g transform="translate(2.828 2.829)">
        <path
          d="M0,0,27.877,27.877,55.753,0"
          transform="translate(0 55.753) rotate(-90)"
          fill="none"
          stroke="#ffa200"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
      </g>
    </svg>
  );
};

export const PlayIcon = ({ iconStyles }) => {
  return (
    <svg
      className={iconStyles}
      width="9.564"
      height="13.643"
      viewBox="0 0 9.564 13.643"
    >
      <path
        d="M596,2764.666l7.564,5.822L596,2776.309Z"
        transform="translate(-595 -2763.666)"
        fill="#fff"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export const ShortArrow = ({ iconStyles }) => {
  return (
    <svg
      className={iconStyles}
      width="22.398"
      height="19.763"
      viewBox="0 0 22.398 19.763"
    >
      <g transform="translate(-968 -5559.151)">
        <g transform="translate(988.983 5560.565) rotate(90)">
          <path
            d="M0,0,8.468,8.468,16.935,0"
            transform="translate(16.935 8.468) rotate(180)"
            fill="none"
            stroke=""
            strokeLinecap="round"
            strokeWidth="2"
          />
          <line
            y2="19.645"
            transform="translate(8.468 0.339)"
            fill="none"
            stroke=""
            strokeLinecap="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};

export const CloseX = ({ iconStyles }) => {
  return (
    <svg
      className={iconStyles}
      width="21.213"
      height="21.213"
      viewBox="0 0 21.213 21.213"
    >
      <g transform="translate(-1857.379 -43.379)">
        <line
          x1="24"
          transform="translate(1859.5 45.5) rotate(45)"
          fill="none"
          stroke="#ffa200"
          strokeLinecap="round"
          strokeWidth="3"
        />
        <line
          x1="24"
          transform="translate(1859.5 62.471) rotate(-45)"
          fill="none"
          stroke="#ffa200"
          strokeLinecap="round"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
};

export const RightArrow = ({ iconStyles }) => {
  return (
    <svg
      className={iconStyles}
      width="9.914"
      height="17.828"
      viewBox="0 0 9.914 17.828"
    >
      <path
        d="M0,0,4.219,4.219,7.5,7.5l3.125-3.125L15,0"
        transform="translate(1.414 16.414) rotate(-90)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </svg>
  );
};

export const Logo = ({ iconStyles }) => {
  return (
    <svg
      className={iconStyles}
      width="221.28"
      height="58.367"
      viewBox="0 0 221.28 58.367"
    >
      <defs>
        <clipPath>
          <rect width="221.28" height="58.367" fill="none" />
        </clipPath>
      </defs>
      <g clipPath="url(#clip-path)">
        <path
          d="M104.659,68.715,102.4,57.133l-1.337,4.61L97.491,73.966l3.576-2.624Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M104.659,68.715,102.4,57.133l-1.337,4.61L97.491,73.966l3.576-2.624Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M85.5,63.362l.8.847,3.043-7.139L85.5,50.9l-1.837-2.919,1.471,7.534-3.516,3.943Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M85.5,63.362l.8.847,3.043-7.139L85.5,50.9l-1.837-2.919,1.471,7.534-3.516,3.943Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M77.516,62.852,74.663,74.97l8.6,14.372H95.691l5.376-9.506,5.545-9.667-5.545,3.421-6.749,4.174,3.728-13.356L93.059,43.254,90.027,62.332,85.5,66.294l-5.175,4.489,5.175-5.89.069-.056-.069-.1L69.356,49.763Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M77.516,62.852,74.663,74.97l8.6,14.372H95.691l5.376-9.506,5.545-9.667-5.545,3.421-6.749,4.174,3.728-13.356L93.059,43.254,90.027,62.332,85.5,66.294l-5.175,4.489,5.175-5.89.069-.056-.069-.1L69.356,49.763Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M94.485,99.108h1.542V93.416h.91l2.339-3.122H80.225l2.339,3.122h.9v5.692h1.547v2.27h9.477Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M94.485,99.108h1.542V93.416h.91l2.339-3.122H80.225l2.339,3.122h.9v5.692h1.547v2.27h9.477Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M167.959,90.224h4.96V85.86H178V82.115H172.92V79.278h5.671V75.783H167.959Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M167.959,90.224h4.96V85.86H178V82.115H172.92V79.278h5.671V75.783H167.959Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M237.388,90.224V79.74H241.3V75.783H228.347V79.74h3.923V90.224Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M237.388,90.224V79.74H241.3V75.783H228.347V79.74h3.923V90.224Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M281.937,85.639l-3.559,1.4a4.863,4.863,0,0,0,3.254,3.317,9.15,9.15,0,0,0,6.437-.618,5.382,5.382,0,0,0,2.567-4.243v-.454a4.44,4.44,0,0,0-2.047-3.633c-1.436-.8-3.08-.736-4.7-1.142a.815.815,0,0,1,.1-1.513,2.564,2.564,0,0,1,2.853,1.309l3.359-1.341c-1.161-3.365-4.947-4.276-8.166-2.979a4.773,4.773,0,0,0-3.247,3.71,4.931,4.931,0,0,0,1.306,4.707c1.211,1.016,3.269.71,4.744,1.305.665.269.743,1.363.054,1.5a2.223,2.223,0,0,1-2.961-1.324"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M281.937,85.639l-3.559,1.4a4.863,4.863,0,0,0,3.254,3.317,9.15,9.15,0,0,0,6.437-.618,5.382,5.382,0,0,0,2.567-4.243v-.454a4.44,4.44,0,0,0-2.047-3.633c-1.436-.8-3.08-.736-4.7-1.142a.815.815,0,0,1,.1-1.513,2.564,2.564,0,0,1,2.853,1.309l3.359-1.341c-1.161-3.365-4.947-4.276-8.166-2.979a4.773,4.773,0,0,0-3.247,3.71,4.931,4.931,0,0,0,1.306,4.707c1.211,1.016,3.269.71,4.744,1.305.665.269.743,1.363.054,1.5A2.223,2.223,0,0,1,281.937,85.639Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M273.921,82.279a6.065,6.065,0,0,0-.516-4.45,4.959,4.959,0,0,0-3.7-2.046h-8.723V90.224h5.056v-3.9l1.1-.3.233.359,2.238,3.845h6.266l-3.654-5.67a3.45,3.45,0,0,0,1.7-2.275m-6.549.264h-1.329V79.415h1.329a1.452,1.452,0,0,1,1.289,1.579,1.506,1.506,0,0,1-1.289,1.549"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M273.921,82.279a6.065,6.065,0,0,0-.516-4.45,4.959,4.959,0,0,0-3.7-2.046h-8.723V90.224h5.056v-3.9l1.1-.3.233.359,2.238,3.845h6.266l-3.654-5.67A3.45,3.45,0,0,0,273.921,82.279Zm-6.549.264h-1.329V79.415h1.329a1.452,1.452,0,0,1,1.289,1.579A1.506,1.506,0,0,1,267.372,82.543Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <rect
          width="5.064"
          height="14.438"
          transform="translate(113.096 32.529)"
          fill="#fff"
        />
        <rect
          width="5.064"
          height="14.438"
          transform="translate(113.096 32.529)"
          fill="#fff"
        />
        <path
          d="M245.366,90.224h10.967V86.661H250.28v-1.9h6.052V81.2H250.28V79.278h6.052V75.783H245.366Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M245.366,90.224h10.967V86.661H250.28v-1.9h6.052V81.2H250.28V79.278h6.052V75.783H245.366Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <rect
          width="5.082"
          height="14.438"
          transform="translate(54.366 32.529)"
          fill="#fff"
        />
        <rect
          width="5.082"
          height="14.438"
          transform="translate(54.366 32.529)"
          fill="#fff"
        />
        <path
          d="M138.945,75.783h-5.051V90.224h5.051v-3.9l1.118-.3.229.359,2.275,3.845H148.8l-3.66-5.67a3.378,3.378,0,0,0,1.692-2.275,5.9,5.9,0,0,0-.49-4.45,5.066,5.066,0,0,0-3.708-2.046h-3.687Zm2.673,5.211a1.556,1.556,0,0,1-1.326,1.549h-1.347V79.415h1.347a1.494,1.494,0,0,1,1.326,1.579"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M138.945,75.783h-5.051V90.224h5.051v-3.9l1.118-.3.229.359,2.275,3.845H148.8l-3.66-5.67a3.378,3.378,0,0,0,1.692-2.275,5.9,5.9,0,0,0-.49-4.45,5.066,5.066,0,0,0-3.708-2.046h-3.687Zm2.673,5.211a1.556,1.556,0,0,1-1.326,1.549h-1.347V79.415h1.347A1.494,1.494,0,0,1,141.618,80.994Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M119.394,82.115h-5.079V79.278h5.662V75.783H109.355V90.224h4.96V85.856h5.079Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M119.394,82.115h-5.079V79.278h5.662V75.783H109.355V90.224h4.96V85.856h5.079Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M199.341,80.35a2.67,2.67,0,0,1,2.48,1.613l4.558-2a7.68,7.68,0,1,0-2.6,9.376l2.785,1.368v-6.8h-4.646a2.711,2.711,0,0,1-5.292-.855,2.71,2.71,0,0,1,2.71-2.708"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M199.341,80.35a2.67,2.67,0,0,1,2.48,1.613l4.558-2a7.68,7.68,0,1,0-2.6,9.376l2.785,1.368v-6.8h-4.646a2.711,2.711,0,0,1-5.292-.855A2.71,2.71,0,0,1,199.341,80.35Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M210.819,75.783V90.224h4.943V85.242h3.8v4.982H224.5V75.783h-4.945v4.93h-3.8v-4.93Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M210.819,75.783V90.224h4.943V85.242h3.8v4.982H224.5V75.783h-4.945v4.93h-3.8v-4.93Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M163.365,79.278V75.783H152.409V90.224h10.956V86.661h-6.039v-1.9h6.039V81.2h-6.039V79.278Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M163.365,79.278V75.783H152.409V90.224h10.956V86.661h-6.039v-1.9h6.039V81.2h-6.039V79.278Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M112.084,61.229V57h1.44V55.844h-4.3V57h1.434v4.226Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M112.084,61.229V57h1.44V55.844h-4.3V57h1.434v4.226Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M124.491,57.038V55.879h-3.567v5.389h3.567V60.1h-2.24v-.968h2.24v-1.16h-2.24v-.938Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M124.491,57.038V55.879h-3.567v5.389h3.567V60.1h-2.24v-.968h2.24v-1.16h-2.24v-.938Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M114.7,55.879v5.389h1.427V59.244h1.67v2.024h1.421V55.879H117.8v2h-1.67v-2Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M114.7,55.879v5.389h1.427V59.244h1.67v2.024h1.421V55.879H117.8v2h-1.67v-2Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M109.077,73.256h7.284V70.567h-3.827V63.331h-3.457Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M109.077,73.256h7.284V70.567h-3.827V63.331h-3.457Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M157.909,66.747l-1.518-3.416h-4.046l3.836,7.023v2.872h3.463V70.455l3.869-7.123h-4.02Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M157.909,66.747l-1.518-3.416h-4.046l3.836,7.023v2.872h3.463V70.455l3.869-7.123h-4.02Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M128.193,73.256h3.673l.466-1.1h3.262l.457,1.1h3.676l-5.753-10.664Zm5.781-3.377h-.717l.717-1.817.7,1.817Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M128.193,73.256h3.673l.466-1.1h3.262l.457,1.1h3.676l-5.753-10.664Zm5.781-3.377h-.717l.717-1.817.7,1.817Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M118.939,73.256h7.492V70.849h-4.109V69.52h4.109V67.09h-4.109V65.775h4.109V63.331h-7.492Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M118.939,73.256h7.492V70.849h-4.109V69.52h4.109V67.09h-4.109V65.775h4.109V63.331h-7.492Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M141.941,63.331v9.9h3.494V70.589l.745-.195.16.267,1.564,2.57h4.267l-2.484-3.84a2.471,2.471,0,0,0,1.165-1.566,4.209,4.209,0,0,0-.368-3.067,3.43,3.43,0,0,0-2.545-1.427l-1.6.031h-.9v-.031Zm4.373,2.539h.026a1,1,0,0,1,.918,1.081,1.025,1.025,0,0,1-.918,1.033h-.9V65.87Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M141.941,63.331v9.9h3.494V70.589l.745-.195.16.267,1.564,2.57h4.267l-2.484-3.84a2.471,2.471,0,0,0,1.165-1.566,4.209,4.209,0,0,0-.368-3.067,3.43,3.43,0,0,0-2.545-1.427l-1.6.031h-.9v-.031Zm4.373,2.539h.026a1,1,0,0,1,.918,1.081,1.025,1.025,0,0,1-.918,1.033h-.9V65.87Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M149.608,101.288c1.67,0,2.736.09,3.546.034a3.948,3.948,0,0,0,2.054-.618c1.109-.727,1.7-1.6,1.618-3.546.08-1.929-.509-2.8-1.618-3.517a4.136,4.136,0,0,0-2.054-.64c-.81-.035-1.876.039-3.546.061Zm2.184-6.5a4.282,4.282,0,0,1,1.362.1c.161.065.282.152.5.251a2.078,2.078,0,0,1,.843,2.025,2.05,2.05,0,0,1-.843,2.024,3.7,3.7,0,0,1-.5.241,3.863,3.863,0,0,1-1.362.135Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M149.608,101.288c1.67,0,2.736.09,3.546.034a3.948,3.948,0,0,0,2.054-.618c1.109-.727,1.7-1.6,1.618-3.546.08-1.929-.509-2.8-1.618-3.517a4.136,4.136,0,0,0-2.054-.64c-.81-.035-1.876.039-3.546.061Zm2.184-6.5a4.282,4.282,0,0,1,1.362.1c.161.065.282.152.5.251a2.078,2.078,0,0,1,.843,2.025,2.05,2.05,0,0,1-.843,2.024,3.7,3.7,0,0,1-.5.241,3.863,3.863,0,0,1-1.362.135Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M173.489,101.288V94.852h2.2v-1.79h-6.544v1.79H171.3v6.436Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M173.489,101.288V94.852h2.2v-1.79h-6.544v1.79H171.3v6.436Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M188.062,92.733a4.378,4.378,0,1,0,0,8.755,4.378,4.378,0,0,0,0-8.755m0,6.534a2.141,2.141,0,1,1,2.147-2.141,2.161,2.161,0,0,1-2.147,2.141"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M188.062,92.733a4.378,4.378,0,1,0,0,8.755,4.378,4.378,0,0,0,0-8.755Zm0,6.534a2.141,2.141,0,1,1,2.147-2.141A2.161,2.161,0,0,1,188.062,99.267Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <rect
          width="2.164"
          height="8.227"
          transform="translate(109.176 49.807)"
          fill="#fff"
        />
        <rect
          width="2.164"
          height="8.227"
          transform="translate(109.176 49.807)"
          fill="#fff"
        />
        <path
          d="M158.626,101.288h2.543l.515-1.212h3.109l.528,1.212h2.545l-4.6-8.75Zm4.639-2.759h-.881l.881-1.947.845,1.947Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M158.626,101.288h2.543l.515-1.212h3.109l.528,1.212h2.545l-4.6-8.75Zm4.639-2.759h-.881l.881-1.947.845,1.947Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M197.578,97.707l4.923,3.814v-8.46h-2.214v3.62L195.373,92.8v8.491h2.205Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M197.578,97.707l4.923,3.814v-8.46h-2.214v3.62L195.373,92.8v8.491h2.205Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M115.008,94.986V93.062H109.45v8.227h2.209V98.5h3.08V96.582h-3.08v-1.6Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M115.008,94.986V93.062H109.45v8.227h2.209V98.5h3.08V96.582h-3.08v-1.6Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M144.07,96.681,139.138,92.8v8.491h2.224V97.707l4.917,3.81V93.062H144.07Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M144.07,96.681,139.138,92.8v8.491h2.224V97.707l4.917,3.81V93.062H144.07Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M121.7,101.488a4.378,4.378,0,0,0,0-8.755,4.378,4.378,0,1,0,0,8.755m0-6.5a2.141,2.141,0,1,1-2.155,2.14,2.152,2.152,0,0,1,2.155-2.14"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M121.7,101.488a4.378,4.378,0,0,0,0-8.755,4.378,4.378,0,1,0,0,8.755Zm0-6.5a2.141,2.141,0,1,1-2.155,2.14A2.152,2.152,0,0,1,121.7,94.986Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M128.782,93.062v5.882c0,1.5,1.57,2.677,3.522,2.677s3.52-1.176,3.52-2.677V93.062h-2.309v5.882c0,.415-.555.787-1.211.787-.685,0-1.211-.372-1.211-.787V93.062Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
        <path
          d="M128.782,93.062v5.882c0,1.5,1.57,2.677,3.522,2.677s3.52-1.176,3.52-2.677V93.062h-2.309v5.882c0,.415-.555.787-1.211.787-.685,0-1.211-.372-1.211-.787V93.062Z"
          transform="translate(-69.356 -43.254)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
