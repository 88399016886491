import React from 'react';
import { Helmet } from 'react-helmet';
import favicon from '@static/favicon.ico';

const Meta = ({
  pageTitle,
  pageDescription,
  shareTitle,
  shareDescription,
  shareImage,
  twitterHandle,
}) => {
  return (
    <Helmet>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />

      <meta property="og:image" content={`https:${shareImage.src}` || ''} />
      <meta property="og:image:width" content={shareImage.width} />
      <meta property="og:image:height" content={shareImage.height} />
      <meta property="og:title" content={shareTitle} />
      <meta property="og:description" content={shareDescription} />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={twitterHandle} />
      <meta name="twitter:title" content={shareTitle} />
      <meta name="twitter:description" content={shareDescription} />
      <meta name="twitter:image" content={`https:${shareImage.src}` || ''} />

      <link rel="icon" type="image/png" href={favicon} />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>
  );
};

export default Meta;
