export const MOBILE_SIZE = 640;

/**
 * @param {String} selector : The selector to query
 * @param {Element} context : The element to search within.
 */

export const q = function q(selector, context) {
  if (typeof window !== `undefined`) {
    return (context || document).querySelector(selector);
  }
  return null;
};

/**
 * @param {String} selector : The selector to query
 * @param {Element} context : The element to search within.
 */
export const qAll = function qAll(selector, context) {
  if (typeof window !== `undefined`) {
    return (context || document).querySelectorAll(selector);
  }
  return null;
};

const isString = value => {
  return Object.prototype.toString.call(value) === '[object String]';
};

export const css = (...args) => {
  let stylesList = [];

  args
    .filter(style => !!style) // remove any falsey values from our styles array and join our style classes.
    .forEach(style => {
      if (Array.isArray(style)) {
        stylesList = stylesList.concat(css(...style)); // Use recursion to handle nested array of styles.
      } else if (isString(style)) {
        stylesList.push(style); // Only add strings to our results
      }
    });

  return stylesList.join(' ');
};

export const mapRange = (value, inMin, inMax, outMin, outMax) => {
  return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
};

export const easeInOutQuad = t => {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
};

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// source: https://davidwalsh.name/vendor-prefix
export const prefix = property => {
  const styles = window.getComputedStyle(document.documentElement, '');
  const pre = (Array.prototype.slice
    .call(styles)
    .join('')
    .match(/-(moz|webkit|ms)-/) ||
    (styles.OLink === '' && ['', 'o']))[1];

  // if on IE use lowercase ms
  if (pre === 'ms') {
    return pre + capitalizeFirstLetter(property);
  }

  return pre[0].toUpperCase() + pre.substr(1) + capitalizeFirstLetter(property);
};

export const setCookie = (cookieName, cookieValue, experationInDays) => {
  const date = new Date();
  date.setTime(date.getTime() + experationInDays * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${cookieName}=${cookieValue};${expires};path=/`;
};

export const getCookie = cookieName => {
  if (typeof document === 'undefined') {
    return '';
  }
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const scrollTo = (destinationOffset, duration = 200) => {
  return new Promise(resolve => {
    const start = window.pageYOffset;
    const startTime =
      'now' in window.performance ? performance.now() : new Date().getTime();

    const documentHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    );
    const windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.getElementsByTagName('body')[0].clientHeight;

    const destinationOffsetToScroll = Math.round(
      documentHeight - destinationOffset < windowHeight
        ? documentHeight - windowHeight
        : destinationOffset
    );

    if ('requestAnimationFrame' in window === false) {
      window.scroll(0, destinationOffsetToScroll);
      resolve();
      return;
    }

    function scroll() {
      const now =
        'now' in window.performance ? performance.now() : new Date().getTime();
      const time = Math.min(1, (now - startTime) / duration);
      const timeFunction = easeInOutQuad(time);
      window.scroll(
        0,
        Math.ceil(timeFunction * (destinationOffsetToScroll - start) + start)
      );

      if (window.pageYOffset === destinationOffsetToScroll) {
        resolve();
        return;
      }

      if (time < 1) {
        requestAnimationFrame(scroll);
      }
    }

    scroll();
  });
};

export const isIE = (() => {
  if (typeof navigator === `undefined`) {
    return false;
  }

  if (
    navigator.userAgent.indexOf('MSIE') !== -1 ||
    navigator.appVersion.indexOf('Trident/') > -1
  ) {
    return true;
  }
  return false;
})();

export const isTouchDevice = (() => {
  if (typeof window !== `undefined`) {
    return 'ontouchstart' in document.documentElement;
  }
  return false;
})();

export const getSlideIndexFromProgress = (direction, modulus, currentSlide) => {
  if (direction === 'left') {
    if (modulus >= 0.3) {
      return currentSlide + 1;
    }

    return currentSlide;
  }
  if (direction === 'right') {
    if (modulus !== 0 && modulus <= 0.7) {
      return currentSlide - 1;
    }
    return currentSlide;
  }
  return currentSlide;
};

export const getParameterByName = (name, url) => {
  const parameterName = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${parameterName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const removeURLParameter = (url, parameter) => {
  // prefer to use l.search if you have a location/link object
  const urlparts = url.split('?');
  if (urlparts.length >= 2) {
    const paramPrefix = `${encodeURIComponent(parameter)}=`;
    const pars = urlparts[1].split(/[&;]/g);

    // reverse iteration as may be destructive
    for (let i = pars.length; i-- > 0; ) {
      // idiom for string.startsWith
      if (pars[i].lastIndexOf(paramPrefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    return urlparts[0] + (pars.length > 0 ? `?${pars.join('&')}` : '');
  }
  return url;
};

export const trackEvent = (
  { category, action, label },
  extraAttributes = {
    customDimension1: null,
    customDimension2: null,
    customDimension3: null,
  }
) => {
  // Only act if we have gtm dataLayer loaded
  if (!(window && window.dataLayer)) return;

  window.dataLayer.push({
    event: 'gtm.event',
    category: category || '',
    action: action || '',
    label: label || '',
    ...extraAttributes,
  });
};
