import React from 'react';
import Styles from './Header.module.scss';

// This component is empty but is used by other components as the destination of
// react portal rendering. https://reactjs.org/docs/portals.html
const Header = () => {
  return <div id="header" className={Styles.header} />;
};

export default Header;
