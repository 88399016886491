import React from 'react';
import useWindowSize from '@hooks/useWindowSize';
import { MOBILE_SIZE, trackEvent, css } from '@utils';
import Styles from './Hamburger.module.scss';

const Hamburger = ({ onClick, pageName }) => {
  const { windowWidth } = useWindowSize();
  const onHamburgerClick = () => {
    trackEvent({
      category: pageName,
      action: null,
      label: 'Menu',
    });

    onClick();
  };

  return (
    <button
      className={Styles.hamburger}
      onClick={onHamburgerClick}
      type="button"
    >
      {windowWidth && windowWidth > MOBILE_SIZE && (
        <div className={Styles.hamburgerText}>Menu</div>
      )}
      <div className={Styles.lineContainer}>
        <div className={css(Styles.line, Styles.line1)} />
        <div className={css(Styles.line, Styles.line2)} />
      </div>
    </button>
  );
};

export default Hamburger;
