import React, { useState, useRef } from 'react';
import { RightArrow } from '@shared/Icons/Icons';
import { css, trackEvent } from '@utils';
import Styles from './Newsletter.module.scss';

const Newsletter = ({ title, placeholder }) => {
  const [emailValue, setEmailValue] = useState('');
  const [emailError, setEmailError] = useState(false);
  const formRef = useRef(null);

  const trackSubmission = () => {
    trackEvent({
      category: 'Footer',
      action: 'Success',
      label: 'Newsletter Submit',
    });
  };

  const onChange = e => {
    setEmailValue(e.target.value);
    setEmailError(false);
  };

  const validEmail = email => {
    // Since email regexes will never cover all basis, this regex
    // just requires an email in the form of: anystring@anystring.anystring

    const re = /\S+@\S+\.\S+/;
    return re.test(String(email).toLowerCase());
  };

  const onSubmit = () => {
    if (validEmail(emailValue)) {
      // submit
      trackSubmission();
      formRef.current.submit();
    } else {
      // show error
      setEmailError(true);
    }
  };

  return (
    <div className={Styles.newsletter}>
      <label htmlFor="newsletter">
        <div className={Styles.newsletterTitle}>{title}</div>
      </label>
      <div className={css(Styles.newsletterForm, emailError && Styles.error)}>
        <form
          ref={formRef}
          action="//learyfirefighters.us10.list-manage.com/subscribe/post?u=adaf510c61f7d3f7ab16efc56&amp;id=3634920263"
          method="post"
          target="_blank"
        >
          <input
            type="hidden"
            name="b_adaf510c61f7d3f7ab16efc56_aea448fa4e"
            tabIndex="-1"
            value=""
          />
          <input
            type="hidden"
            value={emailValue}
            tabIndex="-1"
            name="EMAIL"
            id="mce-EMAIL"
          />
          <input
            type="hidden"
            value="Send"
            tabIndex="-1"
            name="subscribe"
            id="mc-embedded-subscribe"
          />
        </form>

        <input
          id="newlsetter"
          type="email"
          value={emailValue}
          className={Styles.newsletterInput}
          placeholder={placeholder}
          onChange={onChange}
        />
        <div className={Styles.rightArrowContainer} onClick={onSubmit}>
          <RightArrow iconStyles={Styles.rightArrow} />
        </div>
        {emailError && (
          <div className={Styles.emailError}>
            <div className={Styles.emailErrorExclamation}>!</div>
            <div className={Styles.emailErrorCopy}>
              Invalid email, please try again.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Newsletter;
