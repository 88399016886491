import React from 'react';
import { Link } from 'gatsby';
import { Logo } from '@shared/Icons/Icons';
import Head from '@shared/Head/Head';
import Header from '@shared/Header/Header';
import Footer from '@shared/Footer/Footer';
import Nav from '@shared/Nav/Nav';
import DonateButton from '@shared/DonateButton/DonateButton';
import CookieBanner from '@shared/CookieBanner/CookieBanner';
import { css } from '@utils';
import Styles from './layouts.module.scss';
import '@styles/global.scss';

const DefaultLayout = props => {
  const {
    hideDonate,
    yellowLogo,
    footerData,
    pageName,
    cookieBanner,
    children,
  } = props;
  const { facebookLink, twitterLink, instagramLink, youTubeLink } = footerData;

  return (
    <div id="layout" className={Styles.layout}>
      <Head />
      <Header />
      <Link className={Styles.logoLink} to="/">
        <Logo iconStyles={css(Styles.logo, yellowLogo && Styles.privacy)} />
      </Link>
      <Nav
        pageName={pageName}
        socialLinks={[
          {
            label: 'instagram',
            url: instagramLink,
          },
          {
            label: 'facebook',
            url: facebookLink,
          },
          {
            label: 'twitter',
            url: twitterLink,
          },
          {
            label: 'youTube',
            url: youTubeLink,
          },
        ]}
      />
      {!hideDonate && <DonateButton pageName={pageName} />}
      {children}
      <Footer {...footerData} />
      <CookieBanner {...cookieBanner} />
    </div>
  );
};

export default DefaultLayout;
