import React from 'react';
import { Helmet } from 'react-helmet';
import favicon from '@static/favicon.ico';

const Head = () => {
  return (
    <Helmet>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <link rel="icon" type="image/png" href={favicon} />
    </Helmet>
  );
};

export default Head;
