import React, { useState, useEffect } from 'react';
import { css, getCookie, setCookie } from '@utils';
import Styles from './CookieBanner.module.scss';

const CookieBanner = ({ cookieBannerCopy, cookieBannerCtaCopy }) => {
  const [alreadyClosed, setAlreadyClosed] = useState(true);

  useEffect(() => {
    const cookieExists = getCookie('cookie-banner-closed');
    if (!cookieExists) {
      setAlreadyClosed(false);
    }
  }, []);

  const onClose = () => {
    setCookie('cookie-banner-closed', '1', 365);
    setAlreadyClosed(true);
  };

  return (
    <div className={css(Styles.cookieBanner, !alreadyClosed && Styles.showing)}>
      <div className={Styles.copyContainer}>
        <div
          className={Styles.copyText}
          dangerouslySetInnerHTML={{
            __html: cookieBannerCopy.childMarkdownRemark.html,
          }}
        />
        <div className={Styles.agreeButton} onClick={onClose}>
          {cookieBannerCtaCopy}
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;
