import React from 'react';
import { Link, navigate } from 'gatsby';
import { InView } from 'react-intersection-observer';

import { Logo } from '@shared/Icons/Icons';
import Newsletter from '@shared/Newsletter/Newsletter';
import { css, trackEvent } from '@utils';
import Styles from './Footer.module.scss';

const Footer = ({
  mailingAddressLine1,
  mailingAddressLine2,
  mailingAddressCity,
  mailingAddressState,
  mailingAddressZipCode,
  phoneNumber,
  facebookLink,
  twitterLink,
  youTubeLink,
  instagramLink,
  contactEmails,
}) => {
  const onContactEmailClick = (e, email) => {
    trackEvent({
      category: 'Footer',
      action: null,
      label: email,
    });
  };

  const onSocialLinkClick = (e, label, url) => {
    e.preventDefault();

    const newWindow = window.open(url, '_blank');
    newWindow.opener = null;

    trackEvent({
      category: 'Footer',
      action: url,
      label,
    });
  };

  const onPageClick = (e, label, url) => {
    e.preventDefault();
    trackEvent({
      category: 'Footer',
      action: `${window.location.origin}${url}`,
      label,
    });

    navigate(url);
  };

  const currentYear = new Date(Date.now()).getFullYear();
  const privacyLink = (
    <a className={Styles.privacyPolicyLink} href="/privacy">
      Privacy Policy
    </a>
  );

  const socialLinks = [
    {
      label: 'Instagram',
      url: instagramLink,
    },
    {
      label: 'Facebook',
      url: facebookLink,
    },
    {
      label: 'Twitter',
      url: twitterLink,
    },
    {
      label: 'YouTube',
      url: youTubeLink,
    },
  ];

  return (
    <InView triggerOnce threshold={0}>
      {({ inView, ref }) => (
        <div className={css(Styles.footer, inView && Styles.inView)} ref={ref}>
          <div className={Styles.footerContentContainer}>
            <div className={Styles.topSection}>
              <div className={Styles.logoContainer}>
                <Logo iconStyles={Styles.logo} />
              </div>
              <ul className={Styles.footerLinks}>
                <li className={Styles.footerLink}>
                  <Link
                    to="/"
                    onClick={e => {
                      onPageClick(e, 'The Foundation', '/');
                    }}
                  >
                    The Foundation
                  </Link>
                </li>
                <li className={Styles.footerLink}>
                  <Link
                    to="/impact"
                    onClick={e => {
                      onPageClick(e, 'Our Impact', '/impact');
                    }}
                  >
                    Our Impact
                  </Link>
                </li>
                <li className={Styles.footerLink}>
                  <Link
                    to="/apply"
                    onClick={e => {
                      onPageClick(e, 'Apply For A Grant', '/grant');
                    }}
                  >
                    Apply For A Grant
                  </Link>
                </li>
                <li className={Styles.footerLink}>
                  <Link
                    to="/donate"
                    onClick={e => {
                      onPageClick(e, 'Donate', '/donate');
                    }}
                  >
                    Donate
                  </Link>
                </li>
                <li className={Styles.footerLink}>
                  <Link
                    to="/training"
                    onClick={e => {
                      onPageClick(e, 'Training', '/training');
                    }}
                  >
                    Training Hub
                  </Link>
                </li>
              </ul>

              <Newsletter
                title="Interested in our newsletter?"
                placeholder="Enter your email address"
              />

              <div className={Styles.contactUsLabel}>Contact Us</div>

              <div className={Styles.mailingAddressContainer}>
                <div className={Styles.mailingAddress}>
                  {`${phoneNumber}`}
                  <br />
                  {`${mailingAddressLine1} ${
                    mailingAddressLine2 ? `${mailingAddressLine2} ` : ''
                  }`}
                  <br />
                  {`${mailingAddressCity ? `${mailingAddressCity}, ` : ''}
                    ${mailingAddressState ? `${mailingAddressState}, ` : ''}
                    ${
                      mailingAddressZipCode ? `${mailingAddressZipCode} ` : ''
                    }                  
                  `}
                </div>
              </div>

              <div className={Styles.contactEmails}>
                <ul className={Styles.emailsList}>
                  {contactEmails.map((email, index) => {
                    return (
                      <li className={Styles.email} key={index}>
                        <a
                          href={`mailto:${email}`}
                          className={Styles.emailText}
                          onClick={e => onContactEmailClick(e, email)}
                        >
                          {email}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div className={Styles.bottomSection}>
              <ul className={Styles.socialLinks}>
                {socialLinks &&
                  socialLinks.map(({ label, url }, index) => {
                    return (
                      <li key={index} className={Styles.socialLink}>
                        <a
                          href={url}
                          onClick={e => {
                            onSocialLinkClick(e, label, url);
                          }}
                        >
                          {label}
                        </a>
                      </li>
                    );
                  })}
              </ul>
              <div className={Styles.copyright}>
                <span className={Styles.currentYear}>{`©${currentYear}`}</span>
                <span>
                  The Leary Firefighters Foundation | All Rights Reserved |
                </span>
                <span>{privacyLink}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </InView>
  );
};

export default Footer;
